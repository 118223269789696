@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Montserrat:wght@100&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200&family=Quicksand:wght@300;400;500;600&family=Yanone+Kaffeesatz:wght@200;300;400&display=swap');

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.slogan {
  /* text-transform: uppercase; */
  color: rgb(116, 117, 117);
  text-align: center;
  letter-spacing: 5px;
  font-size: 3rem;
  font-family: "Bookman Old Style", serif;
  margin-top: 30px;
}

.subSlogan {
  color: rgb(116, 117, 117);
  letter-spacing: 3px;
  font-size: smaller;
  text-align: center;
  margin-bottom: 20px;
}

.hero-container {
  /* height: 71.7vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}

.input-areas {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.hero-container>h1 {
  color: rgb(68, 66, 66);
  font-size: 100px;
  /* margin-top: -100px; */
}

.hero-container>p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.hero-btns {
  margin-top: 32px;
}

.btn {
  width: 100%;
  background-color: #c9302c !important;
  color: white !important;
}

.footer-input {
  color: rgb(10, 10, 10);
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;
}

.descr {
  text-align: justify;
}

.descr2 {
  text-align: center;
}

.descr3{
  text-align: justify;
}

@media screen and (max-width: 960px) and (min-width: 900px) {
  .hero-container>h1 {
    font-size: 70px;
  }

  .slogan {
    text-align: center;
  }

  .hero-container>h6 {
    text-align: center;
    font-size: smaller;
    padding: 20px;
  }

  .descr {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .input-container {
    font-size: 12px !important;
  }

  .video {
    height: 20vh !important;
  }

  .input-container {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .hero-container>h1 {
    font-size: 50px;
    /* margin-top: -100px; */
  }

  .hero-container>p {
    font-size: 30px;
  }

  .hero-container>h6 {
    text-align: center;
    font-size: smaller;
    padding: 10px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
    background-color: #c9302c !important;
  }

  .slogan {
    text-align: center;
    font-size: 2rem;
    margin-top: 15px;
  }

  .subSlogan {
    margin-bottom: 10px;
  }

  .BlkWhite {
    font-size: 11px !important;
  }

  .descr {
    font-size: 12px !important;
    text-align: justify;
  }

  .descr2 {
    font-size: 12px ;
  }

  .descr3{
    font-size: 12px ;
  }

  .video {
    height: 20vh !important;
  }
}

@media screen and (max-width: 560px) {
  .hero-container>h1 {
    font-size: 30px !important;
  }

  .slogan {
    text-align: center;
  }

  .hero-container>h6 {
    text-align: center;
    font-size: smaller;
    padding: 10px;
  }

  .input-container {
    flex-direction: column;
  }

  .or-text {
    margin-top: 1px;
    margin-bottom: 1px;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .text-muted {
    margin-left: 2px !important;
  }

  .text-th {
    font-size: 17px !important;
  }

  #text-p {
    font-size: 14px !important;
  }

  .slogan {
    text-align: center;
  }

  .hero-container>h6 {
    text-align: center;
    font-size: smaller;
    padding: 10px;
  }

  .hero-btns {
    margin-top: 0px !important;
  }

  .or-text {
    margin: 0px !important;
  }

  .input-container {
    margin: 0px !important;
  }
}

@media screen and (max-width: 380px) {
  .slogan {
    font-size: 25px;
  }
}

@media screen and (max-width: 280px) {
  .hero-container>h1 {
    font-size: 32px !important;
  }

  .hero-container>p {
    font-size: 16px !important;
  }

  form {
    margin: auto;
    width: 80%;
  }

  .slogan {
    text-align: center;
  }

  .hero-container>h6 {
    text-align: center;
    font-size: smaller;
    padding: 20px;
  }

  .input-container {
    width: 100vw !important;
    flex-direction: column !important;
  }

  .btn {
    width: 250px !important;
  }

  .red {
    width: 200px !important;
  }
}

.footer-input {
  padding: 8px 20px;
  border-radius: 5px;
  outline: none;
  font-size: 18px;
  margin-bottom: 16px;
  margin-right: 10px;
  margin-left: 10px;
  border: none;
  height: 50px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, .1607843137254902);
  box-shadow: 1px 1px 1px 1px hsla(0, 0%, 53.3%, 0);
}


/*desktops - higher resolution*/
@media (min-width: 1281px) {
  .footer-input {
    width: 260px;
  }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .footer-input {
    width: 260px;
  }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
  .footer-input {
    width: 260px;
  }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer-input {
    width: 260px;
  }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 601px) and (max-width: 767px) {
  .footer-input {
    width: 260px;
  }
}

@media (min-width: 481px) and (max-width: 599px) {
  .footer-input {
    width: 100%;
    margin: 0px !important;
  }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
  .footer-input {
    width: 100%;
  }
}

p {
  color: #000000 !important;
}

.BlkWhite {
  color: #fff !important;
  margin: 0px !important;
}

.loginContain {
  padding: 5px 30px 5px 30px !important;
}

.firstscreen {
  margin: 0px;
}

.descr  .descr3 {
  font-size: 15px;
  margin: 10px 0px 10px 0px;
  line-height: 15px;
}

.descr2 {
  font-size: 15px;
  margin: 20px;
  line-height: 15px;
}

@media (min-width: 993px) and (max-width: 1260px) {
  .descr3 {
    display: none;
  }
}

@media (min-width: 1300px) and (max-width: 1560px) {
  .descr .descr3 {
    font-size: 12px;
  }
}

@media (min-width: 993px) and (max-width: 1280px) {
  .descr {
    font-size: 12px;
  }
}

.midCol {
  align-items: start !important;
}

.image-container {
  height: 100vh;
  white-space: nowrap;
  margin-bottom: 5px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

/* Add your CSS styles for the component here */
/* You can target the <picture>, <source>, and <img> elements as needed */

/* Example styling for the images */
picture {
  display: block;
  width: 100%;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 0;
}

.imagesz {
  margin-bottom: 5px;
}

@media (max-width: 992px) {
  .poster-image {
    display: none;
    /* Hide Poster5 image on smaller screens */
  }
}

.loginContain {
  position: relative;
}

.social-icons {
  position: fixed;
  bottom: 20px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
}

.social-icon {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

@media (max-width:1100px) {
  .chatbot-container {
    position: fixed;
    bottom: 10px;
    right: 80px;
    width: 70px;
    /* Adjust the width of the collapsed trigger */
    height: 70px;
    /* Adjust the height of the collapsed trigger */
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    /* Rounded in collapsed state */
    overflow: hidden;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
}

@media (max-width:500px) {
  .chatbot-container {
    right: 40px !important;
  }

  .chatbot-container.open {
    width: 90vw !important;
    /* Adjust the width of the expanded chatbot */
    height: 70vh !important;
    /* Adjust the height of the expanded chatbot */
    border-radius: 10px;
    /* Square in expanded state */
    z-index: 2;
  }
}

.chatbot-container {
  position: fixed;
  bottom: 10px;
  right: 80px;
  width: 70px;
  /* Adjust the width of the collapsed trigger */
  height: 70px;
  /* Adjust the height of the collapsed trigger */
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  /* Rounded in collapsed state */
  overflow: hidden;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.chatbot-container.open {
  width: 400px;
  /* Adjust the width of the expanded chatbot */
  height: 600px;
  /* Adjust the height of the expanded chatbot */
  border-radius: 20px;
  /* Square in expanded state */
  z-index: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 10px;
  background: linear-gradient(45deg, #903030, #ff33be);
}

.close-button {
  cursor: pointer;
  color: #fff;
  margin-right: 10px;
}

.chatbot-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 10%;
}

.chatbot-trigger.expanded {
  border-radius: 0;
  /* No border-radius in expanded state */
}

.chatbot-icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0.5, 0.5, 0.5, 0.5);
}

.chatbot-content {
  overflow-y: auto;
  height: 100%;
}

.messages {
  padding-bottom: 60px;
  /* Adjust the padding-bottom to accommodate the collapsed state */
  margin: 10px;
}

.genq {
  display: inline-block;
  align-items: center !important;
  border-radius: 10px;
  background-color: #ffffff;
  color: #7f7f7f;
  border: 3px solid #dddcdc;
  margin: 10px 5px 5px 10px;
  padding: 10px;
  font-style: bold;
  cursor: pointer;
  transition: color 0.0s ease-in-out;
  /* Add a smooth color transition on hover */
}

.genq:hover {
  color: #000000;
  font-weight: 900;
}

.msg {
  margin-top: 20px;
}

.user-message,
.bot-message {
  width: 80%;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.bot-message {
  display: flex;
  align-items: end !important;
  border-radius: 15px 15px 15px 0px;
  /* background-color: #9f9f9f; */
  color: #fff;
  background-color: rgb(165, 66, 91);
}

.user-message {
  background-color: #e9e9e9;
  color: rgb(165, 66, 91);
  text-align: right;
  margin-left: 20%;
  border-radius: 15px 15px 0px 15px;
}

.chat-input {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

input {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

pheader {
  color: #fff;
  margin-left: 10px;
}

.nextButton {
  background-color: rgb(97, 26, 44);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.framework-container #fail-icon {
  height: 30px;
}

@media (min-width:780px) {
  .input-area-card {
    border-radius: 15px;
    width: 90%;
  }
}

@media (min-width:1200px) {
  .input-area-card {
    width: 80%;
  }
}

.featured-banner {
  border-radius: 20px !important;
}

@media (max-width:992px) {
  .featured-banner {
    border-radius: 10px !important;
  }
}

/* QuotesSlideshow.css */
.slideshow-container {
  width: 80%;
  /* Adjust the width as needed */
  margin: auto;
  position: relative;
}

.mySlides {
  display: none;
}

.mySlides.active {
  display: block;
}

.featured-dealer {
  color: #000000 !important;
}

.logo {
  height: auto !important;
}